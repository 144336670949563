import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICompany } from '../interfaces/company.interface';
import { Company } from '../models/company';

@Injectable()
export class CompanyService {
  private baseUrl: string = environment.API.BASE_URL;

  constructor(private http: HttpClient) {}

  /**
   * @return lista de clientes
   */
  getAll(): Promise<ICompany[]> {
    let url = this.baseUrl + '/api/companies';

    url = url.replace(/[?&]$/, '');

    return this.http.get<ICompany[]>(url, {}).toPromise();
  }

  get(companyId: number): Promise<ICompany> {
    let url = `${this.baseUrl}/api/companies/${companyId}`;

    url = url.replace(/[?&]$/, '');

    return this.http.get<ICompany>(url, {}).toPromise();
  }

  create(company: Company): Promise<void> {
    const url = `${this.baseUrl}/api/companies/create`;

    return this.http.post<void>(url, company, {}).toPromise();
  }

  update(company: Company): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${company.id}/update`;

    return this.http.put<void>(url, company, {}).toPromise();
  }

  delete(companyId: number): Promise<void> {
    const url = `${this.baseUrl}/api/companies/${companyId}/delete`;

    return this.http.delete<void>(url, {}).toPromise();
  }
}
