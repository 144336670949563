import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyComponent } from './pages/companies/company.component';
import { ReceiverComponent } from './pages/receivers/receiver.component';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxResponsiveBoxModule,
  DxScrollViewModule,
  DxSpeedDialActionModule,
  DxCheckBoxModule,
  DxDropDownBoxModule,
  DxTreeViewModule,
  DxSelectBoxModule,
} from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { ReceiverDetailsComponent } from './pages/receivers/details/detail.component';
import { ReceiverUpdateComponent } from './pages/receivers/update/update.component';
import { PanelComponent } from './pages/panels/panel.component';
import { EventComponent } from './pages/events/event.component';
import { ConnectionComponent } from './pages/connections/connection.component';
import { ReceiverCardComponent } from './pages/receivers/card/card.component';
import { LoginComponent } from './pages/login/login.component';
import { LoggingComponent } from './pages/receivers/logging/logging.component';
import { FormsModule } from '@angular/forms';
import { ReceiverFormComponent } from './pages/receivers/form/form.component';
import { ReceiverCreateComponent } from './pages/receivers/create/create.component';
import { CompanyCreateComponent } from './pages/companies/create/create.component';
import { CompanyFormComponent } from './pages/companies/form/form.component';
import { CompanyUpdateComponent } from './pages/companies/update/update.component';
import { UserComponent } from './pages/users/user.component';
import { UserCreateComponent } from './pages/users/create/create.component';
import { UserUpdateComponent } from './pages/users/update/update.component';
import { UserFormComponent } from './pages/users/form/form.component';
import { AdminGuard } from './guards/admin-guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    redirectTo: 'companies',
  },
  {
    path: 'companies',
    component: CompanyComponent,
  },
  {
    path: 'companies/:company-id/receivers',
    component: ReceiverComponent,
  },
  {
    path: 'users',
    component: UserComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'receivers',
    component: ReceiverComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    DxDataGridModule,
    DxButtonModule,
    DxFormModule,
    DxResponsiveBoxModule,
    DxPopupModule,
    DxScrollViewModule,
    DxSpeedDialActionModule,
    DxCheckBoxModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxSelectBoxModule,
  ],
  providers: [],
  exports: [RouterModule],
  declarations: [
    CompanyComponent,
    ReceiverComponent,
    ReceiverDetailsComponent,
    LoggingComponent,
    PanelComponent,
    EventComponent,
    ConnectionComponent,
    ReceiverCardComponent,
    ReceiverFormComponent,
    ReceiverCreateComponent,
    ReceiverUpdateComponent,
    CompanyCreateComponent,
    CompanyFormComponent,
    CompanyUpdateComponent,
    UserComponent,
    UserCreateComponent,
    UserUpdateComponent,
    UserFormComponent,
  ],
})
export class AppRoutingModule {}
