import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IEvent } from '../interfaces/event.interface';
import { Event } from '../models/event';
import { throwException } from '../utils/throw-exception';

@Injectable()
export class EventService {
  private baseUrl: string = environment.API.BASE_URL;

  constructor(private http: HttpClient) {}

  /**
   * @param companyId código do cliente
   * @param receiverId instância do receiver
   * @return lista de eventos
   */
  getEvents(companyId: number, receiverId: number): Promise<IEvent[]> {
    let url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/events`;

    url = url.replace(/[?&]$/, '');

    return this.http.get<IEvent[]>(url, {}).toPromise();
  }
}
