import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { IUser } from 'src/app/interfaces/user.interface';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
})
export class UserUpdateComponent implements OnInit {
  user: IUser;
  popupVisible: boolean;
  editing: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private userService: UserService) {
    this.user = new User();
    this.popupVisible = false;
    this.editing = false;
  }

  ngOnInit(): void {}

  showDialog(user: IUser): void {
    this.user = user;
    this.popupVisible = true;
  }

  updateUser(): void {
    this.userService
      .update(this.user)
      .then((response) => {
        notify('Usuário editado com sucesso', 'success');
        this.popupVisible = false;
        this.onUpdated.emit();
      })
      .catch((e) => {});
  }
}
