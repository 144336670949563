<dx-popup
  [height]="550"
  [width]="500"
  [showTitle]="true"
  title="Editar usuário: {{ user.username }}"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
>
  <div *dxTemplate="let data of 'content'" class="dx-scrollable-content">
    <dx-scroll-view width="100%" height="85%">
      <app-user-form [user]="user" [editable]="true"></app-user-form>
    </dx-scroll-view>
    <div
      class="col-md-12 d-flex align-items-end justify-content-center"
      style="height: 15%"
    >
      <dx-button
        stylingMode="contained"
        text="Cancelar"
        type="danger"
        (onClick)="popupVisible = false"
      >
      </dx-button>
      <dx-button
        class="ml-2"
        stylingMode="contained"
        text="Salvar"
        type="success"
        (onClick)="updateUser()"
        [useSubmitBehavior]="true"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>
