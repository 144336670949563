<dx-popup
  [showTitle]="true"
  title="{{ currentReceiver.name }}"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
  class="logging"
>
  <div *dxTemplate="let data of 'content'" class="dx-scrollable-content">
    <dx-scroll-view width="100%" height="100%">
      <ng-template ngFor let-log [ngForOf]="logList">
        <p [innerHtml]="log?.description" [ngStyle]="{ color: log?.color }"></p>
      </ng-template>
    </dx-scroll-view>
  </div>
</dx-popup>
