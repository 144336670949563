import { Component, Input, OnInit } from '@angular/core';
import { ICompany } from 'src/app/interfaces/company.interface';
import { IUser } from 'src/app/interfaces/user.interface';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Input() user!: IUser;
  @Input() editable!: boolean;
  @Input() creating!: boolean;

  companies: ICompany[];

  constructor(private companyService: CompanyService) {
    this.user = new User();
    this.companies = new Array<ICompany>();
  }

  ngOnInit(): void {
    if (this.creating) {
      this.editable = true;
    }

    this.companyService.getAll().then((response) => {
      this.companies = response;

      // if (!this.receiver.companyId && this.companies?.length > 0) {
      //   this.receiver.companyId = this.companies[0].id;
      // }
    });
  }

  getCompaniesNames(): any {
    return this.user.companies?.map((company) => company.name);
  }

  onSelectionChanged(e: any): void {
    this.user.companies = e.selectedRowsData as ICompany[];
  }
}
