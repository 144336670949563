<h2 class="content-block">Usuários</h2>

<hr />

<dx-data-grid
  class="dx-card wide-card"
  keyExpr="id"
  [visible]="visible"
  [dataSource]="users"
  [showBorders]="false"
  [focusedRowEnabled]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="false"
  showScrollbar="true"
  columnResizingMode="widget"
  (onEditingStart)="update($event)"
  (onRowRemoving)="delete($event)"
>
  <dxo-search-panel [visible]="true" [width]="300" placeholder="Pesquisar...">
  </dxo-search-panel>

  <dxo-paging [pageSize]="30"></dxo-paging>

  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 30, 50, 100, 500, 1000]"
    [showInfo]="true"
  >
  </dxo-pager>

  <dxo-editing
    mode="row"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [confirmDelete]="true"
    [useIcons]="true"
    [texts]="{
      confirmDeleteMessage: 'Tem certeza que deseja excluir este Usuário?'
    }"
  >
  </dxo-editing>

  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <dxi-column dataField="username" caption="Usuário"></dxi-column>

  <dxi-column
    [calculateDisplayValue]="getCompaniesNames"
    caption="Empresas"
  ></dxi-column>

  <dxi-column
    dataField="admin"
    caption="É admin?"
    dataType="boolean"
  ></dxi-column>
</dx-data-grid>

<!-- Modal de update -->
<app-user-update (onUpdated)="onCreatedOrUpdated($event)"></app-user-update>

<!-- Modal para create -->
<app-user-create (onCreated)="onCreatedOrUpdated($event)"></app-user-create>

<dx-speed-dial-action
  icon="add"
  title="Adicionar novo Usuário"
  hint="Adicionar novo Usuário"
  [index]="0"
  (onClick)="create()"
>
</dx-speed-dial-action>
