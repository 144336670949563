import { Component, OnInit, ViewChild } from '@angular/core';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { ICompany } from 'src/app/interfaces/company.interface';
import { isAdmin } from 'src/app/services/authentication.service';
import { CompanyService } from 'src/app/services/company.service';
import { ReceiverService } from 'src/app/services/receiver.service';
import { DeleteDialog } from 'src/app/shared/components/delete-confirmation-dialog/delete-dialog';
import { CompanyCreateComponent } from './create/create.component';
import { CompanyUpdateComponent } from './update/update.component';

@Component({
  templateUrl: 'company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  @ViewChild(CompanyCreateComponent)
  companyCreateComponent!: CompanyCreateComponent;

  @ViewChild(CompanyUpdateComponent)
  companyUpdateComponent!: CompanyUpdateComponent;

  isAdmin = isAdmin();

  companies: ICompany[];
  filteredCompanies: ICompany[];

  searchText = '';

  clicked = false;

  constructor(
    private companyService: CompanyService,
    private receiverService: ReceiverService
  ) {
    this.companies = [];
    this.filteredCompanies = [];
  }

  ngOnInit(): void {
    this.getCompanies();
  }

  getCompanies(): void {
    this.companyService.getAll().then((companies: ICompany[]) => {
      this.filteredCompanies = this.companies = companies;
    });
  }

  create(): void {
    this.companyCreateComponent?.showDialog();
  }

  update(company: ICompany): void {
    this.companyUpdateComponent?.showDialog(company);
  }

  stopReceivers(companyId: number): void {
    const dialog = custom({
      title: 'Parando todos os Receivers da empresa',
      messageHtml: `Tem certeza que deseja parar <span style="color: red;">TODOS</span> os receivers desta empresa</strong>?<br><br><small>Isso pode trazer graves consequências.</br>Caso não saiba o que está fazendo entre em contato com nosso suporte!<br><a href="https://sistemairis.com.br/contato/" target="_blank">Fale conosco</a></small>`,
      buttons: [
        {
          text: 'Cancelar',
          stylingMode: 'contained',
          type: 'success',
          onClick: (e) => {
            return false;
          },
        },
        {
          text: 'Parar',
          stylingMode: 'contained',
          type: 'danger',
          onClick: (e) => {
            return true;
          },
        },
      ],
    });

    dialog.show().then((dialogResult: boolean) => {
      if (dialogResult) {
        this.clicked = true;

        this.receiverService
          .stopAllByCompany(companyId)
          .then((response) => {})
          .catch((e) => {})
          .finally(() => {
            this.getCompanies();
            this.clicked = false;
          });
      }
    });
  }

  startReceivers(companyId: number): void {
    const dialog = custom({
      title: 'Iniciando todos os Receivers da empresa',
      messageHtml: `Tem certeza que deseja iniciar <span style="color: red;">TODOS</span> os receivers desta empresa</strong>?`,
      buttons: [
        {
          text: 'Cancelar',
          stylingMode: 'contained',
          type: 'danger',
          onClick: (e) => {
            return false;
          },
        },
        {
          text: 'Iniciar',
          stylingMode: 'contained',
          type: 'success',
          onClick: (e) => {
            return true;
          },
        },
      ],
    });

    dialog.show().then((dialogResult: boolean) => {
      if (dialogResult) {
        this.clicked = true;

        this.receiverService
          .startAllByCompany(companyId)
          .then((response) => {})
          .catch((e) => {})
          .finally(() => {
            this.getCompanies();
            this.clicked = false;
          });
      }
    });
  }

  delete(deletingCompany: ICompany): void {
    const dialog = new DeleteDialog(
      'Excluindo Empresa',
      `Tem certeza que deseja excluir a empresa: <strong>${deletingCompany.name?.toUpperCase()}</strong>?`
    );

    dialog.show().then((dialogResult: boolean) => {
      if (dialogResult) {
        this.companyService.delete(deletingCompany.id).then((response) => {
          notify('Empresa excluida com sucesso', 'success');

          this.filteredCompanies = this.companies = this.companies.filter(
            (company) => company.id !== deletingCompany.id
          );
        });
      }
    });
  }

  onCreatedOrUpdated(event: any): void {
    this.getCompanies();
  }

  onSearch(event: any): void {
    if (event) {
      this.searchText = event.toLowerCase();

      this.filteredCompanies = this.companies.filter(
        (company) =>
          company?.name?.toLowerCase().indexOf(this.searchText) !== -1 ||
          company?.description?.toLowerCase().indexOf(this.searchText) !== -1 ||
          company?.code?.toLowerCase().indexOf(this.searchText) !== -1
      );
    } else {
      this.filteredCompanies = this.companies;
    }
  }
}
