import { Component, Input, OnInit } from '@angular/core';
import { ICompany } from 'src/app/interfaces/company.interface';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-company-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class CompanyFormComponent implements OnInit {
  @Input() company!: ICompany;
  @Input() editable!: boolean;
  @Input() creating!: boolean;

  constructor() {
    this.company = new Company();
  }

  ngOnInit(): void {
    if (this.creating) {
      this.editable = true;
    }
  }
}
