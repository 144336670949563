import { isAdmin } from './services/authentication.service';

export const navigation = () =>
  isAdmin()
    ? [
        {
          text: 'Empresas',
          path: '/companies',
          icon: 'home',
        },
        {
          text: 'Receivers',
          path: '/receivers',
          icon: 'smalliconslayout',
        },
        {
          text: 'Usuários',
          path: '/users',
          icon: 'user',
        },
        {
          text: 'Sair',
          path: '/login',
          icon: 'clear',
        },
      ]
    : [
        {
          text: 'Empresas',
          path: '/companies',
          icon: 'home',
        },
        {
          text: 'Receivers',
          path: '/receivers',
          icon: 'smalliconslayout',
        },
        {
          text: 'Sair',
          path: '/login',
          icon: 'clear',
        },
      ];
