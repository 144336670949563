import { isEmpty } from 'rxjs/operators';

export class KeyValuePair {
  key: any;
  value?: any;

  constructor(key: any, value?: any) {
    this.key = key;
    this.value = value;
  }

  static build(enumType: any): KeyValuePair[] {
    let pair: KeyValuePair[];
    const keys = Object.keys(enumType);

    if (keys.find((key) => !isNaN(+key))) {
      pair = keys
        .filter((key) => !isNaN(+key))
        .map((key: any) => {
          return new KeyValuePair(+key, enumType[key]);
        });
    } else {
      pair = keys.map((key: any) => {
        return new KeyValuePair(key, enumType[key]);
      });
    }

    return pair.sort((a, b) => a.value.localeCompare(b.value));
  }
}
