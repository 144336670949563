import { Component, OnInit } from '@angular/core';
import { IReceiverSettings } from 'src/app/interfaces/receiver-settings.interface';
import { ReceiverSettings } from 'src/app/models/receiver-settings';

@Component({
  selector: 'app-receiver-details',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class ReceiverDetailsComponent implements OnInit {
  receiver: IReceiverSettings;
  popupVisible: boolean;

  constructor() {
    this.receiver = new ReceiverSettings();
    this.popupVisible = false;
  }

  ngOnInit(): void {}

  showDialog(receiver: IReceiverSettings): void {
    this.receiver = receiver;
    this.popupVisible = true;
  }
}
