<dx-data-grid
  class="dx-card wide-card"
  keyExpr="id"
  [visible]="visible"
  [dataSource]="events"
  [showBorders]="false"
  [focusedRowEnabled]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="false"
  showScrollbar="true"
  columnResizingMode="widget"
>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Pesquisar..."
  ></dxo-search-panel>

  <dxo-paging [pageSize]="5"></dxo-paging>

  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 30, 50, 100, 500, 1000]"
    [showInfo]="true"
  ></dxo-pager>

  <dxo-group-panel [visible]="true" emptyPanelText="Agrupar"></dxo-group-panel>

  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <dxi-column
    dataField="eventCode"
    caption="Evento"
    [width]="100"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="clientCode"
    caption="Cliente"
    [width]="150"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="description"
    caption="Descrição"
    [width]="100"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="machine"
    caption="Máquina"
    [width]="80"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="mode"
    caption="Modo"
    [width]="120"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="zone"
    caption="Zona"
    [width]="130"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="partition"
    caption="Particão"
    [width]="130"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="line"
    caption="Linha"
    [width]="80"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="receiverBoard"
    caption="Placa"
    [width]="120"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="receiver"
    caption="Receiver"
    [width]="120"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="identifier"
    caption="Identificador"
    [width]="100"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="occurrenceDateLite"
    caption="Data de ocorrência"
    dataType="date"
    format="dd/MM/yyyy hh:mm:ss"
    [hidingPriority]="0"
  >
  </dxi-column>
</dx-data-grid>
