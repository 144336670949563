<div class="header">
  <h2 class="content-block">Empresas</h2>

  <div class="search-content" p>
    <div b></div>
    <div c>
      <div class="search dark" p>
        <div b></div>
        <div c>
          <i class="dx-icon-search"></i>
          <input
            type="text"
            placeholder="Buscar empresa..."
            [ngModel]="searchText"
            (ngModelChange)="onSearch($event)"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<div class="card-list">
  <div class="card" *ngFor="let company of filteredCompanies">
    <div class="content-block card-item">
      <div class="badges row">
        <div class="dx-badge" title="Receivers Ativos">
          {{ company.receiversRunningCount }}
        </div>
        <div class="dx-badge" title="Receivers Inativos">
          {{ company.receiversCount - company.receiversRunningCount }}
        </div>
      </div>
      <div class="dx-card responsive-paddings">
        <div class="card-header-mine">
          <strong class="card-title text-center">{{ company.name }}</strong>
          <div class="header-btn-group">
            <dx-button
              *ngIf="isAdmin"
              hint="Editar Empresa"
              icon="edit"
              (onClick)="update(company)"
            ></dx-button>
            <dx-button
              *ngIf="isAdmin && company.receiversCount === 0"
              hint="Excluir Empresa"
              icon="trash"
              (onClick)="delete(company)"
            ></dx-button>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-form-label" for="text-input">Código: </label>
          <span class="align-middle">{{ company.code }}</span>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-form-label" for="text-input">Descrição: </label>
          <span class="align-middle">{{ company.description }}</span>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-form-label" for="text-input">Receivers: </label>
          <span class="align-middle">{{ company.receiversCount }}</span>
        </div>
        <div class="card-btn-group">
          <dx-button
            *ngIf="
              isAdmin &&
              company.receiversCount &&
              company.receiversCount !== company.receiversRunningCount
            "
            class="card-btn"
            stylingMode="contained"
            text="Iniciar Receivers"
            [disabled]="clicked"
            type="success"
            (onClick)="startReceivers(company.id)"
          >
          </dx-button>
          <dx-button
            *ngIf="
              isAdmin &&
              company.receiversCount &&
              company.receiversCount === company.receiversRunningCount
            "
            class="card-btn"
            stylingMode="contained"
            text="Parar Receivers"
            [disabled]="clicked"
            type="danger"
            (onClick)="stopReceivers(company.id)"
          >
          </dx-button>
          <dx-button
            class="card-btn"
            stylingMode="contained"
            text="Visualizar Receivers"
            type="default"
            [routerLink]="[company.id, 'receivers']"
          >
          </dx-button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal de update -->
<app-company-update
  *ngIf="isAdmin"
  (onUpdated)="onCreatedOrUpdated($event)"
></app-company-update>

<!-- Modal para create -->
<app-company-create
  *ngIf="isAdmin"
  (onCreated)="onCreatedOrUpdated($event)"
></app-company-create>

<dx-speed-dial-action
  *ngIf="isAdmin"
  icon="add"
  title="Adicionar nova Empresa"
  hint="Adicionar nova Empresa"
  [index]="1"
  (onClick)="create()"
>
</dx-speed-dial-action>
