import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IConnection } from '../interfaces/connection.interface';
import { Connection } from '../models/connection';
import { throwException } from '../utils/throw-exception';

@Injectable()
export class ConnectionService {
  private baseUrl: string = environment.API.BASE_URL;

  constructor(private http: HttpClient) {}

  /**
   * @param companyId código do cliente
   * @param receiverId instância do receiver
   * @return lista de conexões
   */
  getConnections(
    companyId: number,
    receiverId: number
  ): Promise<IConnection[]> {
    let url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/connections`;

    url = url.replace(/[?&]$/, '');

    return this.http.get<IConnection[]>(url, {}).toPromise();
  }
}
