<dx-data-grid
  class="dx-card wide-card"
  keyExpr="codPainel"
  [visible]="visible"
  [dataSource]="connections"
  [showBorders]="false"
  [focusedRowEnabled]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="false"
  showScrollbar="true"
  columnResizingMode="widget"
>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Pesquisar..."
  ></dxo-search-panel>

  <dxo-paging [pageSize]="5"></dxo-paging>

  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 30, 50, 100, 500, 1000]"
    [showInfo]="true"
  ></dxo-pager>

  <dxo-group-panel [visible]="true" emptyPanelText="Agrupar"></dxo-group-panel>

  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <dxi-column
    dataField="codPainel"
    caption="Código Painel"
    [width]="150"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="tipoConexao"
    caption="Tipo de Conexão"
    [width]="150"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="modelo"
    caption="Modelo"
    [width]="130"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="ultimoKeep"
    caption="Último Keep"
    [width]="180"
    dataType="date"
    format="dd/MM/yyyy HH:mm:ss"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="macAddress"
    caption="MAC Address"
    [width]="100"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column dataField="imei" caption="IMEI" [width]="80" [hidingPriority]="0">
  </dxi-column>
  <dxi-column
    dataField="firmWare"
    caption="FirmWare"
    [width]="120"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="iccid"
    caption="ICCID"
    [width]="130"
    [hidingPriority]="0"
  >
  </dxi-column>
</dx-data-grid>
