import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import notify from 'devextreme/ui/notify';
import { IUser } from 'src/app/interfaces/user.interface';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-login',
  styleUrls: ['login.component.scss'],
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
  public user: IUser;
  invalidCredentials: boolean;

  constructor(
    private router: Router,
    private authentication: AuthenticationService
  ) {
    this.invalidCredentials = false;
    this.user = new User();
  }

  ngOnInit(): void {
    if (this.authentication.isAuthenticated()) {
      this.authentication.logout();
    }
  }

  login(): void {
    this.authentication
      .login(this.user)
      .then((response) => {
        if (this.authentication.isAuthenticated()) {
          this.router.navigate(['companies']);
        }
      })
      .catch((e) => {
        this.invalidCredentials = true;
        notify(
          'Verifique seu usuário e senha antes de tentar novamente.',
          'error'
        );
      });
  }
}
