import { Component, HostBinding } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { ScreenService, AppInfoService } from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl: string) => {
        if (
          cl === 'screen-x-small' ||
          cl === 'screen-small' ||
          cl === 'screen-medium' ||
          cl === 'screen-large'
        )
          return this.screen.sizes[cl];
        return this.screen.sizes['screen-medium'];
      })
      .join(' ');
  }

  constructor(
    private screen: ScreenService,
    public appInfo: AppInfoService,
    private authService: AuthenticationService
  ) {}

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }
}
