import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IPanel } from '../interfaces/panel.interface';
import { IPanelStatusList } from '../interfaces/panel-status.interface';
import { Panel } from '../models/panel';
import { PanelStatusList } from '../models/panel-status';
import { throwException } from '../utils/throw-exception';

@Injectable()
export class PanelService {
  private baseUrl: string = environment.API.BASE_URL;

  constructor(private http: HttpClient) {}

  /**
   * @param companyId código do cliente
   * @param receiverId instância do receiver
   * @return lista de painéis
   */
  getPanels(companyId: number, receiverId: number): Promise<IPanel[]> {
    let url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/panels`;

    url = url.replace(/[?&]$/, '');

    return this.http.get<IPanel[]>(url, {}).toPromise();
  }

  /**
   * @param companyId código do cliente
   * @param receiverId instância do receiver
   * @return lista de status de todos os panéis do receiver especificado
   */
  getStatusList(
    companyId: number,
    receiverId: number
  ): Promise<IPanelStatusList> {
    let url = `${this.baseUrl}/api/companies/${companyId}/receivers/${receiverId}/panels/status`;

    url = url.replace(/[?&]$/, '');

    return this.http.get<IPanelStatusList>(url, {}).toPromise();
  }
}
