import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { IUser } from 'src/app/interfaces/user.interface';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class UserCreateComponent implements OnInit {
  user: IUser;
  popupVisible: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCreated: EventEmitter<any> = new EventEmitter();

  constructor(private userService: UserService) {
    this.user = new User();
    this.popupVisible = false;
  }

  ngOnInit(): void {}

  showDialog(): void {
    this.popupVisible = true;
  }

  createUser(): void {
    this.userService
      .create(this.user)
      .then((response) => {
        notify('Usuário criado com sucesso', 'success');
        this.popupVisible = false;
        this.onCreated.emit();
        this.user = new User();
      })
      .catch((e) => {});
  }
}
