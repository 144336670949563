import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { IUser } from 'src/app/interfaces/user.interface';
import { User } from 'src/app/models/user';
import { isAdmin } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { UserCreateComponent } from './create/create.component';
import { UserUpdateComponent } from './update/update.component';

@Component({
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  @ViewChild(UserCreateComponent)
  userCreateComponent!: UserCreateComponent;

  @ViewChild(UserUpdateComponent)
  userUpdateComponent!: UserUpdateComponent;

  visible: boolean;
  users: IUser[];

  constructor(private router: Router, private userService: UserService) {
    this.users = [];
    this.visible = true;

    if (!isAdmin()) {
      this.router.navigate(['login']);
    }
  }

  ngOnInit(): void {
    this.getUsers();
  }

  async getUsers(): Promise<void> {
    await this.userService.getUsers().then((users: IUser[]) => {
      this.users = users;
    });
  }

  create(): void {
    this.userCreateComponent?.showDialog();
  }

  async update(e: any): Promise<void> {
    const user = e.data as IUser;

    e.cancel = true;

    this.userUpdateComponent?.showDialog(user);
  }

  getCompaniesNames(row: any): any {
    return row.admin
      ? 'TODAS'
      : row.companies?.map((company: any) => company.name);
  }

  async delete(e: any): Promise<void> {
    const user = e.data as IUser;

    this.userService.delete(user.id).then((response) => {
      notify('Usuário excluido com sucesso', 'success');
    });
  }

  onCreatedOrUpdated(e: any): void {
    this.getUsers();
  }
}
