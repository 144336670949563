import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { isAdmin } from '../services/authentication.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!isAdmin()) {
      this.router.navigate(['/']);
    }

    return isAdmin();
  }
}
