<dx-data-grid
  class="dx-card wide-card"
  keyExpr="codPainel"
  [visible]="visible"
  [dataSource]="panels"
  [showBorders]="false"
  [focusedRowEnabled]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="false"
  showScrollbar="true"
  (onRowPrepared)="onRowPrepared($event)"
  columnResizingMode="widget"
>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Pesquisar..."
  ></dxo-search-panel>

  <dxo-export
    [enabled]="true"
    [excelFilterEnabled]="true"
    fileName="painéis"
  ></dxo-export>

  <dxo-paging [pageSize]="5"></dxo-paging>

  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 30, 50, 100, 500, 1000]"
    [showInfo]="true"
  ></dxo-pager>

  <dxo-group-panel [visible]="true" emptyPanelText="Agrupar"></dxo-group-panel>

  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <dxi-column
    dataField="codPainel"
    caption="Código Painel"
    [width]="90"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="nome"
    caption="Nome"
    [width]="130"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="status"
    caption="Status"
    [hidingPriority]="0"
    [customizeText]="getStatusValue"
  >
  </dxi-column>
  <dxi-column
    dataField="ultimoKeep"
    caption="Último Keep"
    dataType="date"
    format="dd/MM/yyyy hh:mm:ss"
    [width]="180"
    [hidingPriority]="0"
  >
  </dxi-column>
  <dxi-column
    dataField="tempoKeep"
    caption="Tempo Keep"
    [width]="90"
    [hidingPriority]="1"
  >
  </dxi-column>
  <dxi-column
    dataField="toleranciaKeep"
    caption="Tolerância Keep"
    [width]="90"
    [hidingPriority]="1"
  >
  </dxi-column>
  <dxi-column
    dataField="totalKeep"
    caption="Total Keep"
    [width]="90"
    [hidingPriority]="2"
  >
  </dxi-column>
  <dxi-column
    dataField="modelo"
    caption="Modelo"
    [width]="130"
    [hidingPriority]="2"
  >
  </dxi-column>
  <dxi-column
    dataField="firmWare"
    caption="FirmWare"
    [width]="120"
    [hidingPriority]="3"
  >
  </dxi-column>
  <dxi-column
    dataField="macAddress"
    caption="MAC Address"
    [width]="100"
    [hidingPriority]="3"
  >
  </dxi-column>
  <dxi-column dataField="imei" caption="IMEI" [width]="80" [hidingPriority]="4">
  </dxi-column>
  <dxi-column
    dataField="iccid"
    caption="ICCID"
    [width]="130"
    [hidingPriority]="5"
  >
  </dxi-column>
</dx-data-grid>
