<dx-popup
  [height]="550"
  [width]="500"
  [showTitle]="true"
  title="Editar empresa: {{ company.name }}"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
>
  <div *dxTemplate="let data of 'content'" class="dx-scrollable-content">
    <dx-scroll-view width="100%" height="85%">
      <app-company-form
        [company]="company"
        [editable]="true"
      ></app-company-form>
    </dx-scroll-view>
    <div
      class="col-md-12 d-flex align-items-end justify-content-center"
      style="height: 15%"
    >
      <dx-button
        stylingMode="contained"
        text="Cancelar"
        type="danger"
        (onClick)="popupVisible = false"
      >
      </dx-button>
      <dx-button
        class="ml-2"
        stylingMode="contained"
        text="Salvar"
        type="success"
        (onClick)="updateCompany()"
        [useSubmitBehavior]="true"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>
