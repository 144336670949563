<ng-container *ngIf="isAuthenticated(); else unauthenticated">
  <app-side-nav-inner-toolbar title="{{ appInfo.title }}">
    <router-outlet></router-outlet>
    <!-- <app-footer>
      Copyright © 2011-{{appInfo.currentYear}} {{appInfo.title}} Inc.
      <br/>
      All trademarks or registered trademarks are property of their respective owners.
    </app-footer> -->
  </app-side-nav-inner-toolbar>
</ng-container>

<ng-template #unauthenticated>
  <app-login></app-login>
</ng-template>
