import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IReceiverSettings } from 'src/app/interfaces/receiver-settings.interface';
import { ReceiverSettings } from 'src/app/models/receiver-settings';
import { ReceiverService } from 'src/app/services/receiver.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-receiver-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
})
export class ReceiverUpdateComponent implements OnInit {
  receiver: IReceiverSettings;
  popupVisible: boolean;
  editing: boolean;

  constructor(private receiverService: ReceiverService) {
    this.receiver = new ReceiverSettings();
    this.popupVisible = false;
    this.editing = false;
  }

  ngOnInit(): void {}

  showDialog(receiver: IReceiverSettings): void {
    this.receiver = receiver;
    this.popupVisible = true;
  }

  editReceiverSettings(): void {
    this.receiverService
      .update(this.receiver)
      .then((response) => {
        notify('Configurações editadas com sucesso', 'success');
        this.popupVisible = false;
      })
      .catch((e) => {
        // notify(e.error ?? 'Verifique os dados e tente novamente', 'error');
      });
  }
}
