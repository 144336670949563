import { custom } from 'devextreme/ui/dialog';

export class DeleteDialog {
  dialog: any;

  constructor(title: string | undefined, message: string | undefined) {
    this.dialog = custom({
      title: title ?? 'Excluindo Registro',
      messageHtml: message
        ? `${message}<br><br><small>Esta operação não podera ser desfeita.</br>Caso não saiba o que está fazendo entre em contato com nosso suporte!<br><a href="https://sistemairis.com.br/contato/" target="_blank">Fale conosco</a></small>`
        : `Tem certeza que deseja excluir este registro?<br><br><small>Esta operação não podera ser desfeita.</br>Caso não saiba o que está fazendo entre em contato com nosso suporte!<br><a href="https://sistemairis.com.br/contato/" target="_blank">Fale conosco</a></small>`,
      buttons: [
        {
          text: 'Cancelar',
          stylingMode: 'contained',
          type: 'success',
          onClick: (e) => {
            return false;
          },
        },
        {
          text: 'Excluir',
          stylingMode: 'contained',
          type: 'danger',
          onClick: (e) => {
            return true;
          },
        },
      ],
    });
  }

  show(): Promise<any> {
    return this.dialog.show();
  }
}
