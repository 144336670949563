<dx-form id="user-form" [formData]="user" [colCount]="1">
  <dxi-item dataField="username" required [disabled]="!editable">
    <dxo-label text="Usuário"></dxo-label>
    <dxi-validation-rule
      type="required"
      message="Usuário é obrigatório."
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="password"
    [editorOptions]="{ mode: 'password', minLength: 5 }"
    required
    [disabled]="!editable"
  >
    <dxo-label text="Senha"></dxo-label>
    <dxi-validation-rule
      type="required"
      message="Senha é obrigatória."
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="stringLength"
      [min]="5"
      message="A senha deve conter ao menos 5 caracteres"
    >
    </dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="admin" required [disabled]="!editable">
    <dxo-label text="É admin?"></dxo-label>
  </dxi-item>
  <dxi-item *ngIf="!user.admin" dataField="" required [disabled]="!editable">
    <div class="dx-field-value w-100">
      <dx-drop-down-box
        [value]="getCompaniesNames()"
        valueExpr="name"
        displayExpr="Empresas"
        placeholder="Escolha as empresas.."
        [showClearButton]="true"
        [dataSource]="user.companies"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid
            [dataSource]="companies"
            [selection]="{ mode: 'multiple' }"
            [hoverStateEnabled]="true"
            [filterRow]="{ visible: false }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="300"
            valueExpr="id"
            keyExpr="id"
            (onSelectionChanged)="onSelectionChanged($event)"
          >
            <dxo-search-panel
              [width]="350"
              [visible]="true"
              placeholder="Pesquisar..."
            >
            </dxo-search-panel>
            <dxi-column dataField="name" caption="Selecionar todas">
            </dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>
  </dxi-item>
</dx-form>
