import { ILogInfo } from '../interfaces/log-info.interface';

export class LogInfo implements ILogInfo {
  occurrenceDate?: Date | undefined;
  description?: string | undefined;
  color?: string | undefined;

  constructor(
    occurrenceDate: Date | undefined,
    description: string,
    color: string
  ) {
    this.occurrenceDate = occurrenceDate;
    this.description = description;
    this.color = color;
  }
}
